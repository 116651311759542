import React from 'react'
import BackgroundImage from "gatsby-background-image"

const slider = (sliderData) => {
    return (
        <div className="slider-container">

            {sliderData.map(single_slide => 
                <BackgroundImage className="single-slide" 
                    fluid={single_slide.background_image.localFile.childImageSharp.fluid}
                >
                    <div className="content row"
                    dangerouslySetInnerHTML={{__html: `${single_slide.title} ${single_slide.subtitle} <a href=${single_slide.button.url} target=${single_slide.button.target}><button>${single_slide.button.title}</button></a>`}}
                    >
                    </div>
                </BackgroundImage>
            )}
        </div>
    )
}

const exportSlider = ({sliders}) => {
    if(sliders) {
        return slider(sliders[0].slide);
    } else {
        return true;
    }
}

export default exportSlider;
