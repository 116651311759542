import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import 'foundation-sites'
import "../styles/_style.scss"
import Slider from "../components/slider"

export default ({ data }) => {
  return (
    <Layout>
      <div className="home page-slug">
        <SEO title="Home" 
          description={data.wordpressPage.excerpt}
        />

      <Slider sliders={data.wordpressPage.acf.page_header_page}/>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 33 }) {
      acf {
        page_header_page {
          __typename
          slide {
            background_image{
              localFile {
                absolutePath
                publicURL
                relativePath
                name
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            subtitle
            button {
              title
              url
              target
            }
          }
        }
      }
    }
  }
`